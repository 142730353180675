import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import { Link } from "gatsby"
import React from "react"
import Image from "../Image"

export default ({ episode }) => {
  return (
    <Card key={episode.id}>
      {episode.frontmatter.featuredImage ? (
        <Link to={episode.fields.slug}>
          <CardMedia>
            <Image
              imageInfo={{
                image: episode.frontmatter.featuredImage.img,
                alt: episode.frontmatter.featuredImage.alt,
              }}
            />
          </CardMedia>
        </Link>
      ) : null}
      <CardContent>
        <Typography
          component={Link}
          to={episode.fields.slug}
          gutterBottom
          variant="h6"
          display="block"
          color="inherit"
          noWrap
          style={{ textDecoration: "none", textOverflow: "ellipsis" }}
        >
          {episode.frontmatter.title}
        </Typography>
        <Typography variant="caption" gutterBottom>
          {episode.frontmatter.date}
        </Typography>
        <Typography>{episode.excerpt}</Typography>
      </CardContent>
      <CardActions>
        <Button component={Link} to={episode.fields.slug}>
          Watch Video
        </Button>
      </CardActions>
    </Card>
  )
}
