import React from "react"
import Grid from "@material-ui/core/Grid"
import Card from "./Card"

export class EpisodeList extends React.Component {
  render() {
    const { data, gridItem } = this.props
    const { edges: posts } = data.allMarkdownRemark
    return (
      <Grid container spacing={2}>
        {posts &&
          posts.map(({ node: episode }) => (
            <Grid item key={episode.id} xs={12} md={6} {...gridItem}>
              <Card episode={episode} />
            </Grid>
          ))}
      </Grid>
    )
  }
}
