import React from "react"
import SEO from "../components/seo"
import { graphql, Link as GatsbyLink } from "gatsby"
import Layout from "../components/Layout"
import { EpisodeList } from "../components/Episode/List"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Link from "@material-ui/core/Link"
import Box from "@material-ui/core/Box"

class TagRoute extends React.Component {
  render() {
    const data = this.props.data
    const tag = this.props.pageContext.tag
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} listing${
      totalCount === 1 ? "" : "s"
    } tagged with “${tag}”`
    return (
      <Layout>
        <SEO title={tag} />
        <Container style={{ paddingBottom: "20vh", paddingTop: 16 }}>
          <Typography variant="h4" gutterBottom>{tagHeader}</Typography>
          <EpisodeList data={data} count={totalCount} gridItem={{ md: 4 }} />
          <Box my={4} textAlign="center">
            <Link to="/tags/" component={GatsbyLink}>Browse all tags →</Link>
          </Box>
        </Container>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            featuredImage {
              img {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  }
`
